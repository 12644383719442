import { Component } from '@angular/core';
import { ConstantComponent } from './constant.component';
import { environment } from '../environments/environment';

import { Router, NavigationStart, NavigationEnd, NavigationExtras } from '@angular/router';
import { NgbProgressbarConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./../styles/app.css'],
})
export class AppComponent {
    production: boolean = environment.production;
    staging: string = environment.envName;
    development: string = environment.envName;
    demo: string = environment.envName;
    progress: number = 0;
    public showProgress: boolean = false;
    public currUrl: any;
    process: any;
    constructor(private router: Router, private progressConfig: NgbProgressbarConfig) {
        progressConfig.height = '5px';
        this.router.events.subscribe((event) => {
            this.currUrl = router.url;
            if (this.currUrl !== '/login') {
                if (event instanceof NavigationStart) {
                    this.progress = 5;
                    this.showProgress = true;
                } else if (event instanceof NavigationEnd) {
                    this.progress = 100;
                    setTimeout(() => {
                        this.showProgress = false;
                    }, 500);
                }

            }

        })
        // Live Server
        if (environment.envName === 'prod') {
            ConstantComponent.url = 'https://api.pragatiparivaar.in/api';
            ConstantComponent.port = 'https://api.pragatiparivaar.in';
            // ConstantComponent.reportUrl = 'http://139.59.37.161:8080';
            ConstantComponent.reportUrl =
                'http://pragatiparivaar.in:8080/PragatiLive/frameset?__report=';
            ConstantComponent.nodeUrl = 'https://pragatireports.ajatus.in/metabase/';

        }
        //Staging Server
        else if (environment.envName === 'staging') {
            ConstantComponent.url = 'http://stagingapi.efleetsystems.com/api';
            ConstantComponent.port = 'http://staging.efleetsystems.com';
            ConstantComponent.reportUrl =
                'http://staging.efleetsystems.com:8080/EFleetReports';
            ConstantComponent.nodeUrl = 'https://pragatireports.ajatus.in/metabase/';
        }

        //Development Test Server
        else if (environment.envName === 'development') {
            // ConstantComponent.url = 'https://ospreyapi.ajatus.in/api';
            // ConstantComponent.port = 'https://ospreyapi.ajatus.in/';
            ConstantComponent.url = 'https://apidev-pragati.ajatus.in/api';
            ConstantComponent.port = 'https://apidev-pragati.ajatus.in/';
            ConstantComponent.reportUrl = 'https://reportspragatidev.ajatus.in/PragatiDevelopment/frameset?__report=';
            ConstantComponent.nodeUrl = 'https://pragatireports.ajatus.in/metabase/';
        } else if (environment.envName === 'demo') {
            ConstantComponent.url = 'http://demoapi.efleetsystems.com/api';
            ConstantComponent.port = 'http://demoapi.efleetsystems.com ';
            ConstantComponent.reportUrl =
                'http://150.242.15.70:8080/EFleetReportsDemo';
            ConstantComponent.nodeUrl = 'https://pragatireports.ajatus.in/metabase/';
        }
        // QA Server
        else if (environment.envName === 'qa') {
            ConstantComponent.url = 'http://qaapi.efleetsystems.com/api';
            ConstantComponent.port = 'http://qaapi.efleetsystems.com ';
            ConstantComponent.reportUrl =
                'http://13.234.101.10:8080/EFleetReports';
            ConstantComponent.nodeUrl = 'https://pragatireports.ajatus.in/metabase/';
        }
        //Live Server
        else if (environment.envName === 'live') {
            ConstantComponent.url = 'https://apiprod.efleetsystems.com/api';
            ConstantComponent.port = 'https://apiprod.efleetsystems.com';
            // ConstantComponent.reportUrl = 'http://139.59.37.161:8080';
            ConstantComponent.reportUrl =
                'http://apiprod.efleetsystems.com:8080/EFleetReports';
            ConstantComponent.nodeUrl = 'https://pragatireports.ajatus.in/metabase/';
        }
        //Local Server
        else {

            // ConstantComponent.url = 'https://apidev-pragati.ajatus.in/api';
            // ConstantComponent.port = 'https://apidev-pragati.ajatus.in/';
            // ConstantComponent.reportUrl = 'http://dev-pragati.ajatus.in:8080/PragatiDevelopment/frameset?__report=';
              ConstantComponent.url = 'http://localhost:5000/api';
              ConstantComponent.port = 'http://localhost:5000/';
            // ConstantComponent.url = 'https://api.pragatiparivaar.in/api';
            // ConstantComponent.port = 'https://api.pragatiparivaar.in/';
            ConstantComponent.reportUrl = 'https://reportspragatidev.ajatus.in/PragatiDevelopment/frameset?__report=';
            ConstantComponent.nodeUrl = 'https://pragatireports.ajatus.in/metabase/';

        }
    }
}
